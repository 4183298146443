import React from 'react';
import { navigate } from 'gatsby';
import SeoHeader from './Shared/SeoHeader';
import { Wrapper } from './Shared/Wrapper';
import { Button } from './Shared/Button';
import { ContentWrapper } from './Shared/Wrapper/ContentWrapper';

export function NotFound() {
  return (
    <Wrapper>
      <SeoHeader title="Not Found" />

      <ContentWrapper>
        <section className="mb-5">
          <h1 className="font-hero-rounded text-primary-2 text-6xl">
            Uh Oh! Page not found
          </h1>
          <p>Looks like this page is missing, go back to the home page.</p>
        </section>

        <Button onClick={() => navigate('/')} primary>
          Go Back to Home
        </Button>
      </ContentWrapper>
    </Wrapper>
  );
}
