import { Link } from 'gatsby';
import React from 'react';
import { PublicLayout } from '../components/Shared/PublicLayout';
import { NotFound } from '../components/NotFound';
import SeoHeader from '../components/Shared/SeoHeader';
import { isBrowser } from '../services/auth';

export default function NotFoundPage() {
  return isBrowser() ? (
    <PublicLayout>
      <SeoHeader title="Not Found" />
      <NotFound />
    </PublicLayout>
  ) : null;
}
